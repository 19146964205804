<template>
  <div class="about-us">
    <h3>
      My name is Max Arenstein. I am a software engineer with a history in
      client relations and sales. My past work working with people gives me the
      strategies to work with them directly. I want to use my history with
      working with people to create better software for the end user. I like
      solving problems for people and solving problems with my and my teammates'
      code. People have described me as smart and trustworthy which are
      attributes I try to apply to every job I have. It is my goal to be in a
      role where I can broaden my horizons as well as the ones of people around
      me.
    </h3>
    <p>More info soon!</p>
  </div>
</template>

<script>
export default {
  name: 'AboutUs'
}
</script>

<style scoped>
.about-us {
  margin: 10%;
  text-align: center;
}
</style>